.product_item {
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.2s ease;
  background-color: #FFF;
  flex-grow: 1;
  border-radius: 12px;
}

.login_dino {
  position: absolute;
  top: -200px;
}

.checkbox {
  position: absolute;
  right: 10px;
  top: 21px;
  z-index: 100;
}

.favorite_icon {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

/*
 * Styles for card with variants
*/

// TODO: it's a crutch, see to TODO list in `ProductItem`
.variants_list {
  display: none;
  position: absolute;
  top: 99%;
  left: 0;
  right: -1px;
  background-color: #FFF;
  z-index: 100;
  padding: 5px 24px 19px;
  border-top: none;
  box-shadow: 0 12px 15px rgb(0 0 0 / 25%);
  border-radius: 0 0 2px 2px;
  transition: max-height 0.2s ease;
}

.variants_list.variants_list_open {
  max-height: none;
}

.card_with_variants {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
}

.hover_wrapper {
  position: relative;
}

.show_more_variants {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  background-color: #FFF;
}

.show_more_variants > img {
  width: 17px;
  cursor: pointer;
}

.card_with_variants::before {
  content: "";
  position: absolute;
  bottom: 11px;
  width: 100%;
  height: 1px;
  z-index: 100;
  left: 0;
  right: 0;
}

.card_with_variants:hover::before {
  height: 1px;
  background: #DFE0E0;
}

.card_with_variants:hover .variants_list {
  display: block;
}

.card_with_variants:hover {
  z-index: 100;
  box-shadow: 0 8px 15px rgb(0 0 0 / 15%);
  border-color: transparent;
  transform: scale(1.05);
}

.product_item .goods_item_info {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
}

.product_item .special_percent {
  position: absolute;
  z-index: 1;
  margin-bottom: 10px;
  background: #DF3011;
  color: #FFF;
  padding: 5px 7px;
  font-weight: 500;
  border-radius: 8px;
  width: max-content;
}

.product_item .special_percent.not_avalible {
  opacity: 0.5;
}

.product_item .sold {
  position: absolute;
  z-index: 1;
  background: #FFD42F;
  padding: 5px 7px;
  font-weight: 500;
  border-radius: 2px;
  width: max-content;
  opacity: 0.5;
}

.product_item .good_preview .good_preview_inner {
  display: flex;
  justify-content: center;
}

.product_item .good_preview .good_preview_inner img {
  object-fit: contain;
}

.product_item .good_preview .good_preview_inner.not_avalible {
  opacity: 0.5;
}

.goodCardWrapper{
  display: flex;
  flex-direction: column;
  gap:10px;
  flex-grow: 1;
}

.goodCardWrapper .product_item{
  flex-grow: 1;
}

.product_item .good_link {
  color: #000;
  text-decoration: none;
}

.product_item .good_title {
  margin-top: 20px;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 44px;
  font-weight: 500;
}

.product_item .good_title.not_avalible {
  opacity: 0.5;
}

.product_item .goods_info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}

.product_item .code {
  font-weight: 500;
  font-size: 10px;
  line-height: 158.3%;
  color: #C4C4C4;
}

.product_item .goods_buy {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.product_item .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whishlist_buy_button {
  width: 48px;
  height: 48px;
  padding: 0;
}

.product_item .add {
  display: flex;
  justify-content: flex-end;
  width: 64px;
  margin-bottom: 3px;
  justify-self: end;
}

.product_item .buy_btn {
  grid-column-start: 1;
  grid-column-end: 3;
}

.product_item .buy_btn > button {
  width: 100%;
  border-radius: 12px;
  grid-auto-rows: 2;
}

.product_item .compare,
.product_item .favorites {
  display: flex;
  align-items: flex-end;
}

.product_item .compare button,
.product_item .favorites button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.product_item .order_btn {
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  grid-auto-rows: 2;
}

@media (max-width: 922px) {
  .product_item .price {
  flex-direction: column;
  align-items: start;
  gap: 5px;
  }

  .whishlist_buy_button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .product_item .price {
  flex-direction: row;
  align-items: center;
  }
  .whishlist_buy_button {
    width: 48px;
    height: 48px;
    padding: 0;
  }
}

@media (max-width: 575px) {
  .checkbox {
    top: 20px;
  }

  .product_item .good_preview {
    display: flex;
    justify-content: center;
  }

  .product_item .good_preview .good_preview_inner {
    width: 100px;
  }

  .product_item {
    padding: 10px 5px;
    position: relative;
  }

  .product_item .good_title {
    font-size: 12px;
    line-height: 17px;
    height: 34px;
  }

  .product_item .code {
    display: none;
  }

  .product_item .add {
    position: absolute;
    top: 0;
    right: 0;
    flex-direction: column;
    width: auto;
    padding: 10px;
  }

  .product_item .compare,
  .product_item .favorites {
    justify-content: center;
  }

  .product_item .favorites {
    margin-top: 10px;
  }

  .product_item .goods_buy {
    display: flex;
    flex-direction: column;
  }

  .product_item .price {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  .product_item .buy_btn {
    grid-row: 2 / 3;
    justify-self: end;
    align-self: end;
  }

  .product_item .buy_btn > button {
    width: auto;
    padding: 7px 10px;
  }

  .product_item .buy_btn > button > img {
    margin: 0;
  }

  .product_item .buy_btn > button > span:not(:first-of-type) {
    display: none;
  }
}

@media (max-width: 475px) {
  .checkbox {
    top: 10px;
  }

  .favorite_icon {
    top: -2px;
  }

  .product_item .goods_item_info {
    top: 10px;
  }
}